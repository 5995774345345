import { ReactComponent as LoopyRunnerSvg } from '../assets/runner.svg';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LoopyRunner: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <LoopyRunnerSvg />
    </SvgIcon>
  );
};

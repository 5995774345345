import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { LoopyRunner } from '../LoopyRunner';
import { NAV_LINKS } from './config';
import { Link } from 'react-router-dom';

export function DesktopAppBar() {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
      <Link to="/">
        <LoopyRunner sx={{ mr: 2, fontSize: '8rem', color: 'white' }} />
      </Link>
      {NAV_LINKS.map((page, index) => (
        <Link key={index} to={page.link} style={{ textDecoration: 'none' }}>
          <Button
            key={page.name}
            sx={{ my: 1, mx: 1, color: 'white', display: 'block', fontFamily: 'Fredoka', fontSize: '1rem' }}
          >
            {page.name}
          </Button>
        </Link>
      ))}

    </Box>
  );
}

import './App.css';
import { ResponsiveAppBar } from './components/app_bar/ResponsiveAppBar';
import { Container, Paper } from '@mui/material';
import { Footer } from './components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorPage from './page_components/ErrorPage';
import NotFoundPage from './page_components/NotFoundPage';
import { HomePage } from './page_components/HomePage';
import { GamesPage } from './page_components/GamesPage';
import { CareersPage } from './page_components/CareersPage';

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} errorElement={<ErrorPage />} />
    <Route path="/games" element={<GamesPage />} errorElement={<ErrorPage />} />
    <Route path="/careers" element={<CareersPage />} errorElement={<ErrorPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export function App() {
  return (
    <BrowserRouter basename="/">
      <Container style={{ padding: '0', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <ResponsiveAppBar />
        <Paper sx={{ p: 2, flex: '1' }} square>
          <AppRoutes />
        </Paper>
        <Footer />
      </Container>
    </BrowserRouter>
  );
}

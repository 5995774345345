import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { MobileAppBar } from './MobileAppBar';
import { DesktopAppBar } from './DesktopAppBar';
import { LoopyLogo } from '../LoopyLogo';
import { Box } from '@mui/material';

export function ResponsiveAppBar() {
  return (
    <AppBar position='relative' color='primary'>
      <Box position={'absolute'} width={'100%'} height={'100%'} sx={{ clipPath: 'xywh(0 0px 100% 100% round 0% 0)' }}>
        <LoopyLogo
          style={{
            position: 'fixed',
            top: '-50%',
            left: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '200%',
            height: '200%',
            opacity: 0.1,
            pointerEvents: 'none',
            zIndex: '-1',
          }}
        />
      </Box>
      <Toolbar>
        <DesktopAppBar />
        <MobileAppBar />
      </Toolbar>
    </AppBar>
  );
}

import { Container } from "@mui/material";
import { FC } from "react";

export const HomePage: FC = () => {
  return <Container>
    <p>
      Welcome to Loopy Studios, where we promise to revive social games for all ages.
    </p>
  </Container>

};
import * as React from 'react';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';

export function Footer() {
  return (
    <Paper elevation={4} sx={{ p: 2 }} square>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        Copyright {new Date().getFullYear()} © Loopy Studios, LLC.
      </Box>
    </Paper>
  );
}

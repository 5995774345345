import { Container } from "@mui/material";
import { FC } from "react";

export const GamesPage: FC = () => {
  return <Container>
    <p>
      We have one active game in development: Woot Newts.
    </p>
    <p>
      We want to make a great web game. In the wake of Shockwave Flash and Java in browsers, games like Club Penguin and RuneScape were no longer accessible to all.
      Installing games can be a hinderance for adoption, which is why we want to build experiences in the web browser you already have.
    </p>
    <p>
      Furthermore, we will be using novel technology to iterate on the last state of art. We don't want to revive flash games, we want to surpass them.
      We are building the first vector graphic game engine over WebGPU, utilizing low-latency WebTransport networking, putting curves in physics, and packaging an combat-ready MMO.
    </p>
    <p>
      Open beta coming estimated 2026.
    </p>
  </Container>
};
import { Container } from "@mui/material";
import { FC } from "react";

export const CareersPage: FC = () => {
  return <Container>
    <p>
      We have a lot of work to do, but if you want to join the adventure, we offer sweat-equity stake in our company Loopy Studios, LLC.
    </p>
    <h2>
      Available Roles
    </h2>
    <ul>
      <li>
        Vector Artist (Figma, Illustrator)
      </li>
      <li>
        Vector Animator (LottieLab, LottieFiles, or Bodymovin)
      </li>
      <li>
        Rust Programmer (Bevy experience is a plus)
      </li>
      <li>
        Audio Engineer (MIDI is a plus)
      </li>
      <li>
        Web Engineer (React)
      </li>
    </ul>
    <p>
      Consider joining the team! Please e-mail
    </p>
  </Container>
};